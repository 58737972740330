.table {
  text-align: center;
  width: 100%;
  border: 2px solid orange;
  border-collapse: collapse;
  font-weight: normal !important;
}
.tablehead {
  color: #fff;
  text-align: center;
  background-color: black;
  font-size: 14px;
  border: 1px solid orange;
  padding: 10px;
}
.main-table-container {
  padding: 15px;
}
.verifiedq {
  background-image: linear-gradient(
    to right,
    green 100%,
    #f09819 0%
  ) !important;
}
.tabledata {
  font-size: 15px !important;
  border: 2px solid orange;
  text-align: left;
  background-color: rgb(212, 206, 206);
  padding: 12px;
}
.tabledata2 {
  font-size: 14px;
  border: 2px solid orange;
  text-align: left;
  background-color: rgb(212, 206, 206);
  padding: 5px;
}
.examlist-ttle {
  font-size: 20px !important;
  margin-top: 20px !important;
  width: 70%;
  text-align: center;
  text-decoration: none !important;
}
.title-quiz {
  display: flex;
  justify-content: center;
}
.attemptbtn {
  background-image: linear-gradient(
    to left,
    rgb(4, 85, 4) 100%,
    orange 0%
  ) !important;
  border: 2px solid orange !important;
  color: #fff !important;
  border-radius: 20px !important;
  box-shadow: 0 0 13px #eee !important;
  font-size: 13px !important;
}
.examdetails {
  text-align: left !important;
  font-weight: 200 !important;
  margin: 10px !important;
}
.exam-details-instructions {
  color: rgb(255, 255, 255) !important;
  font-weight: normal !important;
  padding: 15px !important;
  background: #2c2c2c;
  font-size: 19px;
}
.instr-title {
  font-size: 24px;
  color: orange !important;
  text-decoration: underline;
}
.startexam {
  margin-top: 50px !important;
  height: 50px !important;
}

.detailsbtn {
  padding: 10px;
  border-radius: 10px;
  margin-left: 5px;
  margin-right: 5px;
  color: #fff;
  border-width: 0px;
}
.savebtn {
  background-color: #4caf50 !important;
}

.reviewbtn {
  background-color: #ff9800 !important;
}

.submitbtn {
  background-color: #e51c23 !important;
}
.qoptions {
  padding: 14px;
  border: 2px solid white;
  margin-top: 20px;
  min-height: 360px;

  /* background-size: cover; */
}
.attempt-title {
  text-decoration: none !important;
  font-size: 20px !important;
}
.attempt-select {
  width: 60% !important;
  padding: 10px;
  height: 50px;
  background-color: green;
  color: white !important;
  box-shadow: 0 0 13px #eee !important;
  border: 2px solid white;
}
.attempt-select-subject-timeleft {
  display: flex;
  margin-top: 20px;
}
.attempt-option-select {
  background-color: green;
  color: white;
  width: 100% !important;
  word-wrap: break-word !important;
  font-size: large !important;
  /* margin-right: 20px !important; */
  /* text-align: left !important; */
}
.attempt-option-select:hover {
  background-color: green !important;
  color: white !important;
  text-align: left !important;
}
.timer {
  background-color: red;
  color: white;
  box-shadow: 0 0 13px #eee !important;
  border: 2px solid white;
  height: 46px;
  width: 40% !important;
  font-size: small;
  border-radius: 10px;
  text-align: center;
}
.timersec {
  margin-top: -10px !important;
  font-size: 16px;
}
.attempt-btns {
  width: 48% !important;
  border: none !important;
  border-radius: 0px !important;
  box-shadow: none !important;
  height: 40px !important;
  font-size: 14px !important;
}
.attempt-page-btns {
  display: flex;
  justify-content: space-between;
}
.buttons-fixed {
  position: fixed;
  bottom: 0;
  height: 130px;
  background-color: #fff;
  width: 100%;
  margin-left: -20px !important;
}
.back {
  background-image: linear-gradient(to left, red 100%, orange 0%) !important;
  width: 50% !important;
}
.save {
  background-image: linear-gradient(
    to left,
    #4caf50 100%,
    orange 0%
  ) !important;
}

.review {
  width: 24% !important;
  background-image: linear-gradient(
    to left,
    #ff9800 100%,
    orange 0%
  ) !important;
}

.clear {
  width: 24% !important;
  background-image: linear-gradient(
    to left,
    #36b9cc 100%,
    orange 0%
  ) !important;
}

.submit {
  width: 48% !important;
  background-image: linear-gradient(
    to left,
    #e51c23 100%,
    orange 0%
  ) !important;
  /* box-shadow: 0 0 13px #eee !important; */
  /* width: 70% !important; */
  /* border: 2px solid white !important; */
}
.buttons {
  margin-top: 20px;
  /* padding: auto !important; */
  padding-left: 10px;
}
.numbuttonattemt {
  width: 47px !important;
  height: 45px !important;
  margin-right: 5px !important;
  margin-left: 5px !important;
  margin-bottom: 10px !important;
  background-color: black !important;
  border: 1px solid white !important;
  padding: 3px !important;
  font-size: 20px !important;
  color: #fff !important;
}
.numbuttonresult {
  box-sizing: border-box;
  width: 35px !important;
  height: 40px !important;
  margin-right: 5px !important;
  margin-left: 5px !important;
  margin-bottom: 10px !important;
  background-color: black !important;
  border: 1px solid white !important;
  /* padding-right: 15px !important; */
  text-align: center !important;
  /* font-size: 17px !important; */
  color: #fff !important;
  border-radius: 5px !important;
  /* font-size: 14px !important; */
  text-align: center !important;
}
.colorsdiv {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 20px;
}
.colorin {
  height: 40px;
  width: 140px !important;
  /* margin-right: 10px; */
  display: flex;
  flex-direction: column !important;
  justify-content: center !important;
  align-items: center;
  padding: 10px;
  text-align: center;
  background-image: linear-gradient(to left, black 100%, orange 0%);
  color: #fff;
  border: 1px solid white;
  /* margin-bottom: 0px !important; */
}
.submit-popup-btn {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.popbtn {
  width: 240px !important;
  border: 1px solid black !important;
  /* box-shadow: 0 0 13px black !important; */
}
.paynowcontainer {
  display: flex !important;
  flex-direction: row !important;
  justify-content: center !important;
}
.applyTextCoupondiv {
  display: flex !important;
  flex-direction: column !important;
  justify-content: center !important;
  /* width: 120% !important; */
  margin-left: 5px !important;
  margin-top: 2px;
}
.applyCoupon {
  height: 52px !important;
  margin-top: -10px !important;
  width: 150px !important;
}
.apply-coupon-text {
  text-align: center !important;
  font-size: 17px !important;
}
.tablecontainer-container {
  background: linear-gradient(85deg, #434343, #262626) !important;
  border: none !important;
  border-width: 0px !important;
  width: 90vw !important;
}
.tablecontainer-container2 {
  background: linear-gradient(85deg, #434343, #262626) !important;
  border: none !important;
  border-width: 0px !important;
  /* width: 90vw !important; */
}
.accroding-style {
  background: linear-gradient(85deg, #434343, #262626) !important;
  color: #fff !important;
  border: 2px solid orange !important;
  box-shadow: 0 0 13px #eee !important;
  /* width: 88vw !important; */
}
.expandIcon {
  color: #fff !important;
}
.borderstyle {
  border: "2px solid white" !important;
}
.shadeing {
  border: "2px solid white" !important;
  margin-top: 25px !important;
  /* width: 100px !important; */
  /* box-shadow: 0 0 13px #eee !important; */
}
.correctWrongicon {
  display: flex;
  flex-direction: column;
  margin-left: 10px;
  justify-content: center;
}
.whatsappnum {
  position: absolute;
  top: 13%;
  /* left: 50px; */
  opacity: 0.2;
  font-size: 50px !important;
  transform: rotate(-30deg);
}
.categoryHeader {
  border: 2px solid white !important;
  color: orange !important;
  font-weight: bold !important;
  font-size: 15px !important;
  padding: 5px !important;
  text-align: center !important;
}
.tablecell {
  border: 2px solid white !important;
  color: white !important;
  font-weight: bold !important;
  font-size: 14px !important;
  padding: 10px !important;
}
.attemtpnumwater {
  position: fixed;
  top: 230px;
  left: 50px;
  opacity: 0.15;
  font-size: 40px !important;
  z-index: inherit;
}
.attemtpnumwater2 {
  top: 310px;
  transform: rotate(0deg);
}
.attemtpnumwater3 {
  top: 380px;
}
.questionfont {
  font-size: 17px !important;
}
.score-result_list {
  text-align: center !important;
}

.desktopsidebarmenuexamdetails,
.desktopsidebarmenu {
  display: none;
}
.deskbtnsattempt {
  display: none !important;
}
.report {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}
.reportbtn {
  margin-top: 4px !important;
  background-image: linear-gradient(
    to right,
    rgb(47, 132, 182) 100%,
    #f09819 0%
  ) !important;
  /* color: black; */
}
.upcomingBtn {
  height: 90px !important;
}
.rankNew {
  background-image: linear-gradient(to right, #f09819 100%, #fff) !important;
  color: black !important;
  font-size: 16px !important;
}
.upcoming {
  height: 100% !important;
}

.closebtn {
  border-radius: 40px !important;
  width: 30px !important;
  height: 30px !important;
  color: #fff !important;
  background-color: black !important;
}
.dailog2 {
  height: 100vh !important;
  width: 90vw !important;
}
.supportdailog {
  background-color: green !important;
  /* height: 28px !important; */
  color: #fff !important;
}
.sidebar-chat {
  width: 100% !important;
  border-right: 0px solid black !important;
  padding-right: 0px !important;
  /* padding: 10px; */
  overflow-y: scroll;

  scrollbar-width: 5px !important;
}
.eachData {
  padding: 10px !important;
  /* margin-bottom: 10px; */
  cursor: pointer;
}
.eachData:hover {
  background-color: rgb(229, 235, 229);
  cursor: pointer;
  /* padding: 6px; */
}
.dailogContent2 {
  padding: 0px !important
  ;
}
.examschedulelink2 {
  height: 100% !important;
  width: 92% !important;
}
.telangana {
  margin-bottom: -25px !important;
  font-size: 25px !important;
  /* margin-left: 30px !important;
  text-align: left !important; */
}
.chatData {
  background-image: url("../chat_back.jpg");
  background-size: cover;
  height: 100%;
  width: 100vw;
  overflow-y: auto;
}
.chatdiv {
  /* position: absolute;
  right: 0px;
  bottom: 0; */
  display: flex;
  width: 100vw !important;
  /* justify-content: space-around; */
  flex-wrap: wrap;
  padding-top: 10px;
}
.messagesauto {
  background-color: rgb(235, 236, 250);
  color: rgb(30, 37, 123);
  /* margin-bottom: 10px; */
  margin-left: 5px;
  margin-bottom: 10px;
  margin-right: 10px;
  font-size: 14px;
  line-height: 18px;
  padding: 10px;
  border-radius: 6px;
  width: 40%;
  text-align: center;
  cursor: pointer;
}
.sent {
  background-color: #4caf50;
  margin-left: auto;
  margin-top: 20px;
  color: #fff;
}
.sentclass {
  font-size: 10px;
  position: relative;
  /* bottom: -20px; */
  right: 10px;
  color: black;
}
.msgrecived {
  width: 80% !important;
  text-align: left !important;
}
.searchdiv {
  display: flex;
  /* justify-content: space-between; */
  padding: 10px;
  height: 40px;
  justify-content: center;
  /* margin-top: 70vh; */
}
.sendmsgicon {
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: green;
  color: #fff;
  padding-left: 10px;
  padding-right: 10px;
}
.startagain {
  width: 100% !important;
  background-color: orange !important;
  color: #fff;
  cursor: pointer;
  position: fixed;
  /* padding: 0px; */
  margin: 0px !important;
  bottom: 0;
}
.ranktopperofday {
  background-color: orange;
  border-radius: 20px;
  margin-bottom: 20px;
  padding: 10px;
  color: black;
  box-shadow: 0 0 13px #eee !important;
  border: 2px solid black;
}
.ranknametop {
  display: flex;
  flex-direction: row;
  border-radius: 15px;
  border: 2px solid white;
  width: 100%;
  margin-top: 6px;
  /* padding-right: 10px; */
}

.ranknametoprank {
  background-color: white;
  color: black;
  border-radius: 40px;
  width: 30px;
  text-align: center;
  margin-right: 5px;
}
/* .ranknametopscore {
  background-color: green;
  border-radius: 30px;
  width: 50px;
  color: #fff;
  text-align: center;
 
} */
.ranknametopname {
  font-size: 14px !important;
  /* color: #fff !important; */
  font-weight: 600;
}
/* .ranknametop2 {
  margin-top: 15px;
} */
.sharelink {
  background-color: #eee;
  border-radius: 10px;
  color: #262626;
  padding: 10px;
  margin-top: 10px;
}

.referCodediv {
  margin-top: 15px;
  font-size: medium;
  text-align: center;
  border: 2px solid orange;
}

.referCode {
  font-weight: bold;
  font-size: larger;
}

.newUserHome {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.winscratch4 {
  background-color: green;
  color: white;
  /* width: 60%; */
  text-decoration: none !important;
  height: 50px;
  padding: 15px;
  margin-bottom: 10px;
  border-radius: 10px;
  text-align: center;
  margin-top: 10px;
  cursor: pointer;
}
.referEarnUser {
  background-color: orange;
  color: black;
  /* width: 80%; */
  text-decoration: none !important;
  height: 20px;
  padding: 15px;
  border-radius: 10px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  cursor: pointer;
  margin-bottom: 10px;
}
@media (min-width: 767px) {
  .ranknametop2 {
    display: flex !important;
    flex-direction: row !important;
    justify-content: space-around !important;
    flex-wrap: wrap !important;
    margin-top: 15px;
  }
  .ranknametop {
    width: 40% !important;
    padding-right: 10px;
  }

  .winscratch4 {
    background-color: orange;
    color: black;
    /* width: 80%; */
    text-decoration: none !important;
    height: 40px;
    padding: 15px;
    margin-bottom: 10px;
    border-radius: 10px;
    text-align: center;
    margin-top: 10px;
    cursor: pointer;
  }
  .referEarnUser {
    background-color: orange;
    color: black;
    /* width: 80%; */
    text-decoration: none !important;
    height: 20px;
    padding: 15px;
    margin-bottom: 10px;
    border-radius: 10px;
    text-align: center;
    margin-top: 10px;
    cursor: pointer;
  }
  .newUserHome {
    display: flex;
    flex-direction: row !important;
    justify-content: space-around;
  }
  /* .examdetailsdesktop {
     display: flex;
    flex-direction: column;
    margin: auto;
  } */
  .ranktopperofday {
    display: flex;
    flex-direction: column;
    /* justify-content: space-around; */
  }
  .message-content {
    width: 60%;
    padding-left: 20px;
    padding-right: 20px;
  }
  .sidebar-chat {
    width: 30% !important;
    border-right: 2px solid black !important;
    /* padding-right: 10px !important; */
  }
  .examlistattempt {
    width: 90% !important;
  }
  .upcoming {
    margin-left: 20px !important;
    width: 85% !important;
  }

  .attemptbtn {
    margin-left: 20px !important;
  }
  .startexam {
    width: 40% !important;
  }
  .tablecontainer-container {
    width: auto !important;
  }
  .title-quiz {
    justify-content: flex-start;
  }
  .desktopsidebarmenuexamdetails {
    width: 40% !important;
    background-color: black !important;
    border-right: 2px solid white !important;
  }
  .examdetailsdesktop {
    padding-left: 10% !important;
    padding-right: 10% !important;
    width: 100%;
    overflow-y: auto;
    height: 100vh;
  }
  .viewresultsdesktop {
    width: 100%;
    overflow-y: auto;
    height: 100vh;
    /* padding-left: 15% !important;
    padding-right: 15% !important; */
  }
  .viewresultsdesktop22 {
    width: 100%;
    overflow-y: auto;
    height: 100vh;
    padding-left: 15% !important;
    padding-right: 15% !important;
  }
  .homedesktop {
    width: 100%;
    overflow-y: auto;
    height: 100vh;
    padding-left: 5% !important;
    padding-right: 5% !important;
  }
  .homedesktopsidebarmenuexamdetails {
    width: 28% !important;
    background-color: black !important;
    border-right: 2px solid white !important;
  }
  .desksidemenulogout {
    padding: 10px !important;
  }
  .desktopsidebarmenuexamdetails,
  .desktopsidebarmenu {
    display: inline !important;
  }
  .QuestionNumsdesk {
    display: none !important;
  }
  .deskattempt {
    display: flex !important;
    height: 100vh !important;
  }
  .deskbtnsattempt {
    display: inline !important;
    width: 30% !important;
    border-left: 2px solid orange;
    height: 100vh;
    padding: 10px;
    overflow-y: auto;
  }
  .deskQuestions {
    width: 70% !important;
  }
  .questions-desktop {
    display: flex;
  }
  .viewresulttitle {
    text-align: center !important;
    margin-left: -5px !important;
    /* text-decoration: underline !important; */
  }
  .questionstitle {
    padding-left: 25px !important;
    color: #fff;
    font-size: 20px !important;
    text-decoration: underline;
  }
  .buttons-fixed {
    position: relative;
    margin-bottom: 10px !important;
    height: auto;
    margin-left: 0px !important;
    background-color: #fff;
    width: 100%;
    padding: 5px;
    box-sizing: border-box;
  }
  .qoptions {
    padding: 14px;
    border: 2px solid white;
    margin-top: 20px;
    min-height: 300px;
  }
  .timer {
    font-size: 21px !important;
    display: flex;
    justify-content: center;
  }
  .timerleft {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .rankdownloadbtn {
    /* display: flex !important;
    flex-direction: row;
    justify-content: center !important; */
    /* width: 40%; */
    /* margin: auto !important; */
    margin-bottom: 20px !important;
    margin-right: 10px;
  }
  .deskshareapp {
    display: none;
  }
  .timersec {
    font-size: 23px !important;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: -2px !important;
    margin-left: 10px !important;
  }
  .mobile {
    display: none !important;
  }
  .desk {
    display: block !important;
  }
  .edituser {
    margin-top: 30px !important;
  }
  .viewBtn {
    margin-left: 30px !important;
  }
}
.clearIconcss {
  width: 20px !important;
}
.notificationCounter {
  /* position: absolute; */
  /* top: 10; */
  /* right: 0; */
  /* text-align: center; */
  /* z-index: 10; */
  /* border-radius: 40; */
  /* background-color: orange;
  color: white; */
  /* width: 10px; */
}
.supportimg {
  /* position: relative; */
  /* top: -10px; */
  /* width: 100%; */
  /* left: -30px; */
  width: 60px;
  height: 60px;
  /* border-radius: 50px; */
}

.checkiconq {
  width: 10px !important;
  height: 10px !important;
  padding-top: -40px !important;
  z-index: 2 !important;
  font-size: 10px !important;
}
.notattempteed {
  color: white !important;
  margin: auto !important;
  margin-bottom: 1px !important;
}
.paymentdate {
  display: flex;
  font-size: 13px !important;
}
.fontUSerpay {
  font-size: 13px !important;
}
.resp-sharing-button__link,
.resp-sharing-button__icon {
  display: inline-block;
}

.resp-sharing-button__link {
  text-decoration: none;
  color: black;
  margin: 0.5em;
}

.resp-sharing-button {
  border-radius: 5px;
  transition: 25ms ease-out;
  padding: 0.5em 0.75em;
  font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
}

.resp-sharing-button__icon svg {
  width: 1em;
  height: 1em;
  margin-right: 0.4em;
  vertical-align: top;
}

.resp-sharing-button--small svg {
  margin: 0;
  vertical-align: middle;
}

/* Non solid icons get a stroke */
.resp-sharing-button__icon {
  stroke: #fff;
  fill: none;
}

/* Solid icons get a fill */
.resp-sharing-button__icon--solid,
.resp-sharing-button__icon--solidcircle {
  fill: black;
  stroke: none;
}

.resp-sharing-button--whatsapp {
  background-color: orange;
}

.resp-sharing-button--whatsapp:hover {
  background-color: orange;
}
.ScratchCard__Container {
  width: 200 !important;
  height: 200 !important;
}
.ScratchCard__Result {
  background-color: #eee;
  padding: 10px;
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 200px;
  width: 200px;
  margin-bottom: 10px;
}
.winscratch {
  background-color: black;
  color: white;
  /* width: 60%; */
  padding: 15px;
  border-radius: 10px;
  margin-top: 10px;
  text-align: center;
  cursor: pointer;
}
.winscratch2 {
  background-color: orange;
  color: black;
  /* width: 60%; */
  text-decoration: none !important;
  height: 30px;
  padding: 15px;
  margin-bottom: 10px;
  border-radius: 10px;
  text-align: center;
  margin-top: 10px;
  cursor: pointer;
}
.winscratch3 {
  background-color: orange;
  color: black;
  /* width: 60%; */
  text-decoration: none !important;
  height: 90px;
  padding: 15px;
  margin-bottom: 10px;
  border-radius: 10px;
  text-align: center;
  margin-top: 10px;
  cursor: pointer;
}

.scratchContent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px !important;
  cursor: pointer;
}
.remainingcunt {
  background-color: yellow;
  padding: 5px;
  border-radius: 10px;
  text-align: center;
}
.chat-widget-container {
  position: fixed;
  bottom: 20px;
  right: 20px;
}

.chat-widget-btn {
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  cursor: pointer;
}

.chat-widget {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  background-color: #fff;
}

.title {
  margin: 10px 0;
  text-align: center;
}

.chat-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.chat-list li {
  padding: 10px;
  cursor: pointer;
  border-bottom: 1px solid #ccc;
}

.chat-list li:last-child {
  border-bottom: none;
}

.chat-list li:hover {
  background-color: #f0f0f0;
}

.chat-details {
  padding: 10px;
}

.back-btn {
  padding: 5px 10px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.message-input-container {
  display: flex;
  margin-top: 10px;
}

.message-input {
  flex: 1;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.send-btn {
  padding: 8px;
  margin-left: 10px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.send-btn:hover {
  background-color: #0056b3;
}

/* Media query for mobile devices */
@media (max-width: 767px) {
  .chat-widget {
    width: 100%;
    max-width: 400px;
    margin: 0 auto;
    overflow-y: auto;
  }
}


.download-banner {
  /* position: fixed; */
  margin-top: 30px;
  /* bottom: 0; */
  width: 100%;
  background-color: #f0f0f0;
  padding: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.download-banner p {
  margin-right: 10px;
}

.download-banner img {
  width: 100px; /* Adjust size as needed */
  height: auto;
}
.chat-button {
  background-color: #4CAF50; /* Green background */
  border: none; /* No border */
  color: white; /* White text */
  padding: 15px 32px; /* Padding */
  text-align: center; /* Center text */
  text-decoration: none; /* No underline */
  display: inline-block; /* Display as inline-block */
  font-size: 16px; /* Font size */
  margin: 4px 2px; /* Margin */
  cursor: pointer; /* Cursor on hover */
  border-radius: 8px; /* Rounded corners */
}
