.chat-widget-container {
    position: fixed;
    bottom: 20px;
    right: 20px;
  }
  
  .chat-widget-btn {
    padding: 10px 20px;
    background-color: #007bff;
    color: white;
    border: none;
    cursor: pointer;
  }
  
  .chat-widget {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
    background-color: #fff;
  }
  
  .title {
    margin: 10px 0;
    text-align: center;
  }
  
  .chat-list {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  .chat-list li {
    padding: 10px;
    cursor: pointer;
    border-bottom: 1px solid #ccc;
  }
  
  .chat-list li:last-child {
    border-bottom: none;
  }
  
  .chat-list li:hover {
    background-color: #f0f0f0;
  }
  
  .chat-details {
    padding: 10px;
  }
  
  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  
  .back-btn,
  .close-btn {
    padding: 5px 10px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .message-input-container {
    display: flex;
    margin-top: 10px;
  }
  
  .message-input {
    flex: 1;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  .send-btn {
    padding: 8px;
    margin-left: 10px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .send-btn:hover {
    background-color: #0056b3;
  }
  /* Your existing CSS */

.message {
    padding: 8px;
    border-radius: 8px;
    margin: 8px;
    max-width: 70%;
  }
  
  .sent {
    background-color: #007bff;
    color: white;
    align-self: flex-end;
  }
  
  .received {
    background-color: #f0f0f0;
    color: #333;
    align-self: flex-start;
  }
  
  /* Your existing CSS */
  
  /* Media query for mobile devices */
  @media (max-width: 767px) {
    .chat-widget {
      width: 100%;
      max-width: 400px;
      margin: 0 auto;
      overflow-y: auto;
    }
  }
  .chat-details {
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: calc(100% - 40px); /* Subtract padding and header height */
  }
  
  .message-input-container {
    display: flex;
    margin-top: 10px;
    position: fixed;
    bottom: 10px;
    width: 90%;
  }
  
  .message-input {
    flex: 1;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  .send-btn {
    padding: 8px;
    margin-left: 10px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .send-btn:hover {
    background-color: #0056b3;
  }
  .messages-container{
    padding-bottom: 40px;
    
  }
  .chat-widget {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
    background-color: #fff;
    transition: transform 0.3s ease-in-out; /* Add a transition */
   }
 
  .chat-list li {
    padding: 10px;
    cursor: pointer;
    border-bottom: 1px solid #ccc;
    transition: background-color 0.3s ease; /* Add transition */
  }
  
  .chat-list li:last-child {
    border-bottom: none;
  }
  
  .chat-list li:hover {
    background-color: #f0f0f0;
  }
  /* Default styles */
.chat-list {
    list-style: none;
    padding: 0;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    
  }
  
  .chat-list li {
    border: none; /* Remove the border on desktop */
    padding: 15px;
    margin-bottom: 10px;
  }
  
  .chat-list li:hover {
    background-color: #f0f0f0;
  }
  
  /* Responsive styles for desktop */
  @media screen and (min-width: 768px) {
 
    .chat-list li {
        padding: 10px;
        margin-right: 20px;
        margin-bottom: 20px;
        cursor: pointer;
      }
    .chat-list{
        display: flex;
        flex-direction: row !important;
        height: 100vh;
        overflow-y: scroll;
    }
    .messages-container{
      padding-bottom: 40px;
      height: 100vh;
      overflow-y: scroll;
    }
  }
  