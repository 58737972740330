* {
  margin: 0;
}

.login-container {
  padding: 20px;
}

.packages-container {
  padding-left: 20px;
  padding-right: 20px;
}

.login-buttons-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 30px;
  background: linear-gradient(85deg, #434343, #262626);
  border-radius: 15px;
  box-shadow: 0 0 20px #eee !important;
  /* height: 35vh; */
}

.support {
  /* color: #fff !important; */
  background-color: black;
  width: 100px;
  height: 24px;
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;
  padding-bottom: 5px;
  position: fixed;
  bottom: 0px;
  right: 5px;
  /* z-index: 10; */
  text-align: center;
  color: #fff;
  cursor: pointer;
  border: 2px solid green;
  box-shadow: 0 0 20px white !important;
}

.borderpup {
  border: 3px solid rgb(10, 173, 10) !important;
}

.packages-inner-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  border: 2px solid #fff;
  background: linear-gradient(85deg, #434343, #262626);
  border-radius: 15px;
  /* box-shadow: 0 0 20px #eee !important; */
  /* height: 30vh; */
  margin-bottom: 15px;
}

.btn {
  background-image: linear-gradient(to right,
      #ff512f 0%,
      #f09819 51%,
      #ff512f 100%);
  transition: 0.5s;
}

.input-box {
  background-color: #fff;
  border-radius: 5px;
  margin-bottom: 10px !important;
  height: 50px;
}

.heading {
  color: orange;
  margin-bottom: 16px;
  text-align: center;
  font-size: 18px;
}

.login-title-co {
  display: flex;
  flex-direction: column !important;
  justify-content: center !important;
}

.submit-otp-div {
  display: flex;
  flex-direction: row;
}

.back-button {
  margin-top: 5px;
  margin-right: 20px;
  color: orange;
  font-size: medium;
}

.otp-mesage-text {
  color: orange;
  margin-bottom: 10px;
  font-size: 14px !important;
}

.loader-spinner {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.loader-img {
  width: 90px;
  height: 90px;
  border-radius: 70px;
  position: absolute;
  z-index: 1;
  margin-top: 8px;
}

.not-found-img {
  width: 400px;
  height: 400px;
}

.not-found-div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* margin-top: 10vh; */
  background-color: #fff !important;
  height: 100vh;
}

.linkto {
  text-decoration: none;
  color: #000000 !important;
}

.header-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 20px;
}

.heading-title {
  font-size: 40px;
  background: -webkit-linear-gradient(#fc4a1a, #f7b733);
  -webkit-background-clip: text;
  text-align: center;
  -webkit-text-fill-color: transparent;
}

.desktoptitle {
  font-size: 33px !important;
}

.desktoppara {
  font-size: 20px !important;
}

.heading-para {
  font-size: 24px;
  font-weight: bold;
  background: -webkit-linear-gradient(#777372, #ffffff);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-align: center;
  margin-top: -20px;
}

.userbuttons-header {
  position: fixed;
  right: 0px;
  bottom: 20px;
}

.navbar {
  background: linear-gradient(85deg, #434343, #262626);
  box-shadow: 0 0 20px #eee;
  /* margin: 10px; */
  padding: 10px;
  margin-top: 10px;
  border-radius: 15px;
}

.mobile-icon {
  color: orange;
  font-size: medium;
  border: 1px solid white;
  padding: 2px;
  margin-left: 10px;
}

.nav-buttons {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  color: orange;
}

.marg {
  padding-right: 25px !important;
}

.menu-parar {
  font-size: 17px;
  margin-left: 10px;
}

.header-btns {
  width: 100%;
  margin-top: 15px !important;
  font-weight: bold !important;
  color: black !important;
  height: 35px !important;
}

.package-btns-containers {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 15px;
}

.packagebtn {
  width: 60%;
  margin-right: 10px !important;
  font-size: 14px !important;
  text-transform: capitalize !important;
  padding: 10px !important;
}

.package-bottom {
  background: linear-gradient(85deg, #434343, #262626);
  border: 1px solid orange !important;
  margin-top: 10px !important;
  font-size: 14px !important;
  /* width: 100%; */
}

.package-name {
  background: -webkit-linear-gradient(#fc4a1a, #f7b733);
  -webkit-background-clip: text;
  text-align: center;
  -webkit-text-fill-color: transparent;
  text-transform: capitalize !important;
}

.userBox-2 {
  background: -webkit-linear-gradient(#fc4a1a, #f7b733);
  -webkit-background-clip: text;
  text-align: center;
  -webkit-text-fill-color: transparent;
  text-transform: capitalize !important;
}

.gotoexamsbtnhome {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100% !important;
}

.exbtn {
  width: 60% !important;
  margin-bottom: 10px !important;
  background-image: linear-gradient(to left, red 100%, orange 0%) !important;
  color: #fff !important;
  border: 2px solid orange !important;
}

.userName {
  font-size: 23px;
  font-family: cursive !important;
}

.userName2 {
  color: #fff !important;
  font-size: 16px;
  text-align: center;
}

.loader-main-container {
  margin-top: 40vh !important;
}

.closebtn {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  font-size: 13px;
}

.closebtn-modal {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 30px !important;
  margin-left: 0px !important;
}

.clsebtn {
  font-size: 30px !important;
  color: red;
}

.instructions-register {
  color: #fff;
  margin-top: 10px;
  margin-bottom: 10px;
}

.main {
  color: orange;
  font-size: 17px !important;
  text-align: center;
  margin-bottom: 10px;
}

.main-register {
  text-decoration: underline;
  font-size: 17px !important;
}

.form-register {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.activateacoountbtn {
  background-image: linear-gradient(to right,
      #000000 0%,
      #434343 51%,
      #000000 100%);
  color: #fff;
  border: 2px solid orange !important;
}

.home-button {
  font-size: 35px !important;
  margin: 0px !important;
}

.homepage-package-title {
  font-size: 26px;
  color: orange;
  margin-left: 10px;
  margin-top: 10px;
  text-decoration: underline;
  text-align: center;
}

.homepage-package-packages {
  font-size: 17px;
  color: white;
  margin-left: 10px;
  margin-top: 10px;
  text-align: center;
  margin-bottom: 10px;
}

.newpurchased {
  font-size: 12px !important;
  margin-right: 10px !important;
  padding: 5px !important;
}

.register {
  padding: 2px !important;
  border: 1px solid orange !important;
}

.selectds {
  display: flex;
  flex-direction: column !important;
  justify-content: flex-start !important;
}

.dis {
  padding: 8px !important;
}

.buy {
  width: 40vw;
  background: linear-gradient(85deg, #434343, #262626) !important;
  border-radius: 15px;
  padding: 40 px !important;
  border: 2px solid white !important;
  color: #fff !important;
  height: 40px !important;
  font-size: 16px !important;

  /* box-shadow: 0 0 20px #eee !important; */
}

.buypack {
  background: linear-gradient(85deg, red 100%, #262626) !important;

  /* text-decoration: none !important; */
}

.paymentfailedimg {
  width: 100vw !important;
}

/* .desktop-menus {
  display: none;
} */
.gotoExamsbtn {
  background: linear-gradient(85deg, green 100%, #262626) !important;
  /* text-decoration: none !important; */
  height: 40px !important;
  width: 70% !important;
}

.homedesktopsidebarmenuexamdetails,
.desktopsidebarmenuexamdetails,
.desktopsidebarmenu {
  display: none;
}

.tabledataPurchased {
  color: #fff;
  padding-left: 5px !important;
}

.linksche2 {
  color: #fff !important;
}

.rupee-icon {
  color: white;
  font-size: 20px;
  margin-right: 20px;
  background: linear-gradient(85deg, rgb(50, 71, 50), rgb(128, 124, 124));
  /* box-shadow: #eee; */
  border: 2px solid orange;
  border-radius: 50px;
  height: 30px;
  padding-left: 10px;
  padding-bottom: 5px;
  width: 60px;
  text-align: center;
}

.rupee-icon-span {
  color: white;
  /* font-size: 15px !important; */
  /* margin-right: 15px; */
}

@media (min-width: 767px) {
  .package-name {
    display: flex;
    flex-direction: column !important;
    justify-content: center !important;
    align-items: center !important;
    min-height: 100px !important;
  }

  .header-container {
    display: none !important;
  }

  .desktopsidebar {
    display: flex;
  }

  .desktopsidebarmenu {
    /* width: 17rem !important; */
    height: auto;
    width: 40% !important;
    background-color: black !important;
    border-right: 2px solid white !important;
  }

  .desktopcontnet {
    margin: auto;
    padding-left: 15% !important;
    padding-right: 15% !important;
    overflow-y: auto;
    width: 100%;
    /* height: 100vh; */
  }

  .login-container {
    padding-left: 30%;
    padding-right: 30%;
    padding-top: 50px;
  }

  .buy {
    width: 150px !important;
  }

  .linksche {
    color: #000000 !important;
  }

  .dividers {
    display: none !important;
  }

  .homepage-package-title {
    text-align: left !important;
    margin-bottom: 10px !important;
  }

  .amount {
    font-size: 15px !important;
  }

  .homepacksdesk {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }

  .btnexam {
    width: 80% !important;
  }

  .gotoexamsbtnhome {
    display: flex;
    flex-direction: row;
    justify-content: center !important;
    width: 120% !important;

    margin-left: -20px !important;
  }

  .gotoExamsbtn {
    font-size: 12px !important;
  }

  .deskmenustyle {
    display: flex;
    margin: 5px;
  }

  .deskmenuIcon {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 10px;
    margin-right: 15px;
    color: green;
  }

  .deskmenuPara {
    font-size: 18px;
    color: white;
  }

  .homedesktopsidebarmenuexamdetails,
  .desktopsidebarmenuexamdetails,
  .desktopsidebarmenu {
    display: inline !important;
  }

  .userName {
    font-size: 26px;
    font-family: cursive !important;
  }

  .userName2 {
    color: #fff !important;
    font-size: 19px;
    text-align: center;
  }

  .packagedesk {
    padding-left: 20px;
    padding-right: 20px;
    width: 250px !important;
    /* margin: auto; */
  }

  .desktopregister {
    margin-top: 20px !important;
  }

  .amount {
    font-size: 12px !important;
  }
}

.userprofile {
  margin-top: 20px !important;
}