@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.11.3/font/bootstrap-icons.min.css");
body {
  margin: 0;
  font-family: "Ramabhadra";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: linear-gradient(85deg, #434343, #262626);
  /* border-radius: 15px; */
  /* box-shadow: -2rem 0 3rem -2rem #000; */
  /* height: 20vh; */
  height: auto;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
@media (min-width: 768px) {
  /* body {
    padding-left: 11% !important;
    padding-right: 11% !important;
  } */
}
.MuiFormLabel-root.Mui-focused {
  color: green !important;
}
