/* SubjectSelection.css */

.subject-selection-container {
     width:100vw;
    margin-top: 5%;
    /* height: 50vh; */
    /* padding: 20px; */
    background-color: #e7e1e1;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    text-align: center;
    /* margin-left: 2px;
    margin-right: 2px; */
  }
  
  h2 {
    margin-bottom: 20px;
    font-size: 24px;
    color: #333;
  }
  
  .subjects-grid {
    display: flex;
   flex-direction: row;
   justify-content: center;
   flex-wrap: wrap;
   
  }
  
  .subject-item {
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 5px;
    padding: 10px;
    transition: all 0.3s ease;
    cursor: pointer;
    margin-top: 10px;
    margin-right: 10px;
  }
  
  .subject-item:hover {
    transform: translateY(-3px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .subject-item.selected {
    background-color: #4caf50;
    color: white;
  }
 .submibtn{
    margin-top: 50px !important;
    margin-bottom: 10px !important;
  }  