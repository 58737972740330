.admin {
  padding-left: 2% !important;
  padding-right: 2% !important;
}
.useradmincountdiv {
  display: flex;
  flex-direction: column;
  margin-top: 15px;
}
@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.rotate {
  display: inline-block; /* Optional: To ensure proper spacing */
  animation: rotate 2s linear infinite; /* 2s is the duration, change if needed */
}

.dashdiv {
  display: flex !important;
  flex-direction: row !important;
  justify-content: space-around !important;
  background-color: #fff;
  border-radius: 10px;
  padding: 12px;
}
.columndiv {
  width: 90%;
}
.reporteditdata {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.columncenter {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 15px;
}
.desktopsidebarmenuexamdetailsAdmin {
  width: 26% !important;
  background-color: black !important;
  border-right: 2px solid white !important;
  display: none !important;
}
.repoteddiv {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.repored-image {
  width: 80%;
  border-radius: 20px;
}

.blackborder {
  border-left: 10px solid black;
  margin-bottom: 10px;
}
.greenborder {
  border-left: 10px solid green;
  margin-bottom: 10px;
}
.orangeborder {
  border-left: 10px solid orange;
}
.title {
  color: green;
  font-size: 17px;
}
.count {
  color: orange;
  font-size: 23px;
}
.paiduserdiv {
  background-color: white;
  padding: 12px;
  border-radius: 10px;
  margin-top: 15px;
}
.dashboardtable {
  width: auto;
  overflow-x: auto;
  overflow-y: auto;
  height: auto;
}
.dashboardtablehead {
  background-color: orange;
}
.dashth {
  border: 2px solid black;
  padding: 3px;
  text-align: center;
}
.exportbtn {
  color: #fff !important;
  margin-bottom: 10px !important;
}
.navigate {
  color: #fff !important;
  margin-right: 10px !important;
  margin-top: 10px !important;
}
.pagination {
  overflow-x: auto !important;
}
.popupdata {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 10px;
}
.selectionbox {
  width: 100%;
  padding: 5px !important;
  font-size: large !important;
}
.input-container-packages {
  width: 98%;
  border-radius: 5px;
  height: 30px;
  padding-left: 10px;
  margin-bottom: 10px;
}
.wne {
  width: 98.3% !important;
}
.input-packageeeditorrow {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.input-container-packages-down {
  width: 161% !important;
}
.input-packageeeditorcloum {
  display: flex;
  flex-direction: column;
}
.insched {
  width: 98.3% !important;
}
.adminTableButtons {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 10px;
}
.catgroy {
  width: 95% !important;
  color: black !important;
}
.packagepopup {
  display: flex !important;
  flex-direction: column !important;
  justify-content: center !important;
}
.detailsExams {
  background-color: green;
  padding: 8px;
  border-radius: 10px;
  margin-top: 10px;
  color: #fff;
  margin-right: 10px;
}
.addnewExam {
  background-color: #fff;
  padding: 30px;
  margin-top: 15px;
  margin-bottom: 15px;
  border-radius: 15px;
}
.input-box-add-exam {
  width: 79.3%;
  height: 30px;
  margin-bottom: 15px;
}
/* table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

td,
th {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 10px;
  height: "100%";
}

tr:nth-child(even) {
  background-color: #dddddd;
} */
.innerMenuclik {
  background-color: green;
  border-radius: 15px;
  margin: 10px;
  padding: 10px;
}
.innermenuclr {
  color: black !important;
}

@media (min-width: 768px) {
  .desktopsidebarmenuexamdetailsAdmin {
    display: inline !important;
  }
  .input-container-packages-down {
    width: 121% !important;
  }
  .packagepopup {
    display: flex !important;
    flex-direction: row !important;
    justify-content: center !important;
  }
  .input-packageeeditorrow {
    justify-content: space-around !important;
    margin-left: -55px !important;
  }
  .adminTableButtons {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  .useradmincountdiv {
    display: flex !important;
    flex-direction: row !important;
    justify-content: space-between !important;
    margin-top: 15px;
  }
  .showbtnsdiv {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 10px;
    margin-top: -11px;
  }
  .showbtns {
    background-color: orange;
    color: black;
    border-width: 0px;
  }
  .automaticselction {
    margin-top: 20px;
    border: 2px solid black;
    padding: 15px;
    margin-bottom: 20px;
  }
  .reporteditdata {
    display: flex;
    flex-direction: row !important;
    justify-content: space-around !important;
  }
}

